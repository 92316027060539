/* eslint-disable import/no-cycle */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Page } from '../../../questions/questions.service';
import { MessageService } from '../../services/message.service';
import { FormService } from './form-elements/form.service';
import { PageElement } from './models/page-element';
import { Question } from './models/question';
import { PageBuilderService } from './page-builder.service';

// eslint-disable-next-line
export enum fieldElements {
  address = 'address',
  autocomplete = 'autocomplete',
  autoRadio = 'auto-radio',
  checkbox = 'checkbox',
  date = 'date',
  email = 'email',
  num = 'number',
  radio = 'radio',
  attachedRadio = 'attached-radio',
  radioCard = 'radio-card', // I'm not sure we ever actually set a type to 'radio-card, maybe consider removing it
  select = 'select',
  tel = 'tel',
  text = 'text',
  toggle = 'toggle',
  hidden = 'hidden',
}

export interface PageBuilderOptions {
  buttonText?: string;
  removeSubmit?: boolean;
  readonly?: boolean;
}

@Component({
  selector: 'kin-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss'],
  providers: [FormService, PageBuilderService],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PageBuilderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() page: Page;

  @Input() fields: PageElement[];

  @Input() disableSubmit = false;

  @Input() action: string;

  @Input() layout = 'v-list v-list__x2@medium';

  @Input() method: string;

  @Input() options: PageBuilderOptions = {
    buttonText: 'Submit',
    removeSubmit: false,
    readonly: false,
  };

  @Output() onSubmit: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @ViewChild('formElement') formElement: HTMLFormElement;

  @ViewChild('submit_button') button: ElementRef;

  formFields: Question[];

  form: FormGroup;

  constructor(
    public formService: FormService,
    public messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.prepareForm();

    const roofSolarPanelsControl = this.form.get('roof_solar_panels');
    if (roofSolarPanelsControl) {
      roofSolarPanelsControl.valueChanges.subscribe((value) => {
        const solarPanelStatusQuestionElement = document.getElementById(
          'quote_property_information_solar_panel_status'
        );
        solarPanelStatusQuestionElement.style.display = 'none';

        if (solarPanelStatusQuestionElement) {
          if (value === false || '') {
            solarPanelStatusQuestionElement.style.display = 'none';
            const solarPanelStatusControl = this.form.get('solar_panel_status');
            if (solarPanelStatusControl) {
              solarPanelStatusControl.clearValidators();
              solarPanelStatusControl.setValue(undefined);
              solarPanelStatusControl.updateValueAndValidity();
            }
          } else {
            solarPanelStatusQuestionElement.style.display = 'block';
            const solarPanelStatusControl = this.form.get('solar_panel_status');
            if (solarPanelStatusControl) {
              solarPanelStatusControl.setValidators([Validators.required]);
              solarPanelStatusControl.updateValueAndValidity();
            }
          }
        }
      });
    }

    setTimeout(() => {
      const solarPanelStatusQuestionElement = document.getElementById(
        'quote_property_information_solar_panel_status'
      );
      if (solarPanelStatusQuestionElement) {
        solarPanelStatusQuestionElement.style.display = 'none';
      }
    }, 0);
  }

  ngAfterViewInit() {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);

      // If the control has been pre-filled and has async validators, manually trigger status change
      if (control.value !== '' && control.asyncValidator) {
        control.updateValueAndValidity();
      }
    });
  }

  // Check to see if the changes detected have updates to the fields
  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields) {
      this.compareFields(changes.fields.previousValue, changes.fields.currentValue);
    }
  }

  trackByFn(index, item) {
    return item.id;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  compareFields(oldFields: PageElement[], newFields: PageElement[]) {
    if (oldFields) {
      const isChanged = oldFields
        .map((field, i) => {
          return field === newFields[i];
        })
        .filter((isEqual) => isEqual === false);

      if (isChanged) {
        this.prepareForm();
      }
    }
  }

  // Using the list above (fieldElements) we filter out the Page Elements that are not form fields.
  prepareForm() {
    this.formFields = this.fields.filter((field) =>
      Object.values(fieldElements).find((el) => el === field.type)
    ) as Question[];
    this.form = this.formService.prepareForm(this.formFields);
  }

  // Bubble the submit event up to the submitting component.
  fireSubmitEvent() {
    this.onSubmit.emit(this.form);
  }

  isFormField(field: PageElement): boolean {
    return Object.values(fieldElements).find((el) => el === field.type)?.length !== undefined;
  }
}
