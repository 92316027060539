import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KinterestedObject } from './fullstory.service';
import { SessionStorageService } from './session-storage.service';

// Parts of this code inspired / taken from https://github.com/mzuccaroli/angular-google-tag-manager/
// This service is to interact with, start up, and otherwise manage the relationship we have with Google Tag Manager.
// Please reach out to the Marketing team for any questions regarding anything within Google Tag Manager.

export enum SegmentEvent {
  USER_LOGIN = 'user_login',
  QUOTE_VIEWED = 'self_serve_quote_seen',
  QUOTE_SUBMITTED = 'self_serve_quote_submitted',
}

@Injectable({
  providedIn: 'root',
})
export class TagManagerService implements OnDestroy {
  storageSubscription: Subscription;

  private loaded = false;

  private segmentUserLoaded = false;

  private config = { id: environment.gtmId || null };
  /* eslint-disable */
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };
  /* eslint-enable */

  constructor(private sessionStorageService: SessionStorageService) {}

  ngOnDestroy() {
    this.storageSubscription.unsubscribe();
  }

  public initialize() {
    if (this.loaded) {
      return;
    }

    this.storageSubscription = this.sessionStorageService.sessionStorageSubscription.subscribe(
      (data) => {
        if (data === 'user') {
          this.sendUserLoginEventToDataLayer(this.sessionStorageService.retrieve('user'));
        }
      }
    );

    if (this.config.id === null || !environment.production) {
      return;
    }

    const doc = this.browserGlobals.documentRef();
    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const gtmScript = doc.createElement('script');
    gtmScript.id = 'gtm';
    gtmScript.async = true;
    gtmScript.src = this.applyGtmQueryParams('https://www.googletagmanager.com/gtm.js');
    doc.head.insertBefore(gtmScript, doc.head.firstChild);

    this.loaded = true;
  }

  public getDataLayer() {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  public sendEventToDataLayer(event: string, data: Record<string, unknown>) {
    const userInfo: KinterestedObject = JSON.parse(this.sessionStorageService.retrieve('user'));
    if (userInfo.kin_segment_user_id) {
      this.pushOnDataLayer({
        event,
        data: {
          ...userInfo,
          ...data,
          variant: 'v0', // So we know the tracking event comes from the old ng-kin funnel
        },
      });
    }
  }

  private sendUserLoginEventToDataLayer(data: string) {
    const info: KinterestedObject = JSON.parse(data);
    if (info.kin_segment_user_id && !this.segmentUserLoaded) {
      this.pushOnDataLayer({
        event: 'user_login',
        kin_segment_user_id: info.kin_segment_user_id,
      });
      this.segmentUserLoaded = true;
    }
  }

  private pushOnDataLayer(obj: Record<string, unknown>) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private applyGtmQueryParams(url: string) {
    let updatedUrl = url;
    if (updatedUrl.indexOf('?') === -1) {
      updatedUrl += '?';
    }

    return (
      updatedUrl +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }
}
